import * as React from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import ItalRow from "../../../components/italrow"

const IBDTreatmentGuidelines = () => (
  <Layout>
	<Helmet>
        <script src={"/js/main.js"} type="text/javascript" />
    </Helmet>
    <Seo title="Current IBD Treatment Guidelines" description="Select clinical guidelines for the treatment of inflammatory bowel disease" />
    <header className="headline">
	<img src={"/images/black-and-green-ibd-science-graphic-image.svg"} alt="ibd science gastroenterology" />
	</header>
	<section className="wrap"><h2 className="perfectSpace">Curated and continually updated content to advance the science and treatment of IBD</h2><br/>
	<header className="orange rule"><h1 id="ibd-treatment-guidlines">IBD <span>TREATMENT GUIDELINES</span></h1></header>
		
		<section className="tabContent wrap treatment-guidelines">
	  <div className="content rows selected ">
		 <ItalRow  
				title="Clinical guidelines for the management of inflammatory bowel disease"
				author={<p>Khanna R, Wilson AS, Gregor JC, Prowse KL, Afif W </p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/34534537/"
                description={<span><em>Gastroenterology</em>, 2021</span>}
			/>

<ItalRow  
				title="ECCO guidelines on therapeutics in ulcerative colitis: medical treatment"
				author={<p>Raine T, Bonovas S, Burisch J, et al</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/34635919/"
                description={<span><em>Journal of Crohn’s and Colitis</em>, 2021</span>}
			/>

<ItalRow  
				title="STRIDE-II: An update on the selecting therapeutic targets in inflammatory bowel disease (STRIDE) initiative of the International Organization for the Study of IBD (IOIBD): determining therapeutic goals for treat-to-target strategies in IBD"
				author={<p>Turner D, Ricciuto A, Lewis A, et al</p>} 
                link="https://www.gastrojournal.org/article/S0016-5085(20)35572-4/fulltext?referrer=https%3A%2F%2Fpubmed.ncbi.nlm.nih.gov%2F"
                description={<span><em>Gastroenterology</em>, 2021</span>}
			/>

<ItalRow  
				title="Evidence-based clinical practice guidelines for inflammatory bowel disease 2020"
				author={<p>Nakase H, Uchino M, Shinzaki S, et al</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8137635/"
                description={<span><em>Journal of Gastroenterology</em>, 2020</span>}
			/>

<ItalRow  
				title="American Gastroenterological Association Institute clinical guideline on the management of moderate to severe ulcerative colitis"
				author={<p>Feuerstein JD, Isaacs KL, Schneider Y, et al</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7175923/ "
                description={<span><em>Gastroenterology</em>, 2020</span>}
			/>

<ItalRow  
				title="American Gastroenterological Association technical review on the management of moderate to severe ulcerative colitis"
				author={<p>Singh S, Allegretti JR, Siddique SM, Terdiman JP</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7117094/"
                description={<span><em>Gastroenterology</em>, 2020</span>}
			/>

<ItalRow  
				title="Treatment algorithm for mild and moderate-to-severe ulcerative colitis: an update"
				author={<p>Burri E, Maillard MH, Schoepfer AM, et al</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/31945767/"
                description={<span><em>Digestion</em>, 2020</span>}
			/>

<ItalRow  
				title="Ulcerative colitis—diagnostic and therapeutic algorithms"
				author={<p>Kucharzik T, Koletzko S, Kannengiesser K, Dignass A</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8171548/"
                description={<span><em>Deutsches Ärzteblatt International</em>, 2020</span>}
			/>

<ItalRow  
				title="American Gastroenterological Association Institute guideline on the management of mild-moderate ulcerative colitis"
				author={<p>Ko CW, Singh S, Feuerstein JD, et al</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6858922/"
                description={<span><em>Gastroenterology</em>, 2019</span>}
			/>
</div>	
	
</section>
	</section>
    <section className="spacer end"></section>
	<footer className="definitions">ECCO=European Crohn’s and Colitis Organisation; IBD=inflammatory bowel disease.</footer>
  </Layout>
)

export default IBDTreatmentGuidelines
