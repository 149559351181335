import * as React from "react"

const ItalRow = ( {title, author, link, description} ) => {
  return (
	 <>
     <a href={link} target="_blank" rel="noreferrer">
      <h5>{title}</h5>
		<>{author}</>
		<div><hr/><img src={"/images/view-button.svg"} alt="View" /></div>
		{description}
	  </a>
	  </>
  )
}


export default ItalRow
